import CitiesSlider from "./CitiesSlider";

export default function AboutSliderSection() {
  return (
    <section className="relative pb-20">
      <hr className="mx-auto w-4/5 border-t-2 border-gray-300" />
      <div className="container mx-auto px-4 py-20">
        <div className="flex flex-wrap justify-center text-center mb-24">
          <div className="w-full lg:w-6/12 px-4">
            <h2 className="text-4xl font-semibold">La ciudad perfecta</h2>
            <p className="text-lg leading-relaxed m-4 text-gray-600">
              Nuestra IA seleccionará la ciudad que más os convenga en base a
              vuestros gustos, presupuesto e itinerario.
            </p>
          </div>
        </div>
        <CitiesSlider />
      </div>
      <hr className="mx-auto w-4/5 border-t-2 border-gray-300" />
    </section>
  );
}
