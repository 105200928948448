import React, { useState } from "react";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { usePreferences } from "../../hooks/usePreferences";
import { useAuthContext } from "../../hooks/useAuthContext";

export default function StagPartyForm() {
  const { user } = useAuthContext();

  const [formData, setFormData] = useState({
    groomBrideName: "",
    budget: "",
    travelAvailabilityStart: "",
    travelAvailabilityEnd: "",
    travelPreferences: "",
    riskyActivities: "",
    parties: "",
    activitiesPerDay: "",
    foodPreference: "",
    accommodationPreference: "",
    destinationPreferences: "",
    additionalComments: "",
    email: user.email,
  });

  const { sendPreferences, isLoading, error } = usePreferences();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "number") {
      const regex = /^[0-9]*$/;
      if (!regex.test(value)) {
        return;
      }
    }

    if (type === "checkbox" && Array.isArray(formData[name])) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: checked
          ? [...prevFormData[name], value]
          : prevFormData[name].filter((item) => item !== value),
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: type === "checkbox" ? (checked ? value : "") : value,
      }));
    }
  };

  const handleMutuallyExclusiveChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: prevFormData[name] === value ? "" : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = [
      "groomBrideName",
      "budget",
      "travelAvailabilityStart",
      "travelAvailabilityEnd",
      "travelPreferences",
      "riskyActivities",
      "parties",
      "activitiesPerDay",
      "foodPreference",
      "accommodationPreference",
      "destinationPreferences",
    ];

    for (let field of requiredFields) {
      if (
        !formData[field] ||
        (Array.isArray(formData[field]) && formData[field].length === 0)
      ) {
        toast.error("Añade todos los campos!");
        return;
      }
    }

    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;

    if (!token) {
      toast.error("Usuario no autenticado!");
      return;
    }

    const response = await sendPreferences(formData, token);

    if (response.error) {
      toast.error(`Error: ${response.error}`);
    } else {
      toast.success("Las preferencias se han enviado correctamente!!");
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="mx-auto p-6 bg-white shadow-md rounded"
    >
      <ToastContainer theme="dark" className="mt-20" />
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-1/2 px-4">
          <div className="mb-6">
            <label
              htmlFor="groomBrideName"
              className="block text-sm font-bold mb-2"
            >
              Nombre del novio o de la novia:
            </label>
            <input
              type="text"
              id="groomBrideName"
              name="groomBrideName"
              value={formData.groomBrideName}
              onChange={handleChange}
              className="border rounded w-full py-2 px-3 focus:outline-none focus:ring-2 focus:ring-gray-600"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="budget" className="block text-sm font-bold mb-2">
              Presupuesto:
            </label>
            <input
              type="number"
              id="budget"
              name="budget"
              value={formData.budget}
              onChange={handleChange}
              className="border rounded w-full py-2 px-3 focus:outline-none focus:ring-2 focus:ring-gray-600"
              required
              min="0"
            />
          </div>

          <div className="mb-6">
            <label
              htmlFor="travelPreferences"
              className="block text-sm font-bold mb-2"
            >
              Preferencias de viaje:
            </label>
            <div className="flex flex-wrap gap-4">
              {["Aventura", "Relajación", "Cultural"].map((pref) => (
                <div className="flex" key={pref}>
                  <input
                    type="checkbox"
                    id={`travelPreferences-${pref}`}
                    name="travelPreferences"
                    value={pref.toLowerCase()}
                    checked={formData.travelPreferences === pref.toLowerCase()}
                    onChange={() =>
                      handleMutuallyExclusiveChange(
                        "travelPreferences",
                        pref.toLowerCase()
                      )
                    }
                    className="peer hidden"
                  />
                  <label
                    htmlFor={`travelPreferences-${pref}`}
                    className="select-none cursor-pointer rounded-lg border-2 border-gray-200 py-3 px-6 font-bold text-gray-800 transition-colors duration-200 ease-in-out peer-checked:bg-gray-200 peer-checked:text-gray-900 peer-checked:border-gray-200"
                    style={{ height: "52px" }}
                  >
                    {pref}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-6">
            <label
              htmlFor="riskyActivities"
              className="block text-sm font-bold mb-2"
            >
              Actividades de riesgo:
            </label>
            <div className="flex space-x-4">
              {["Sí", "No"].map((option) => (
                <div className="flex" key={option}>
                  <input
                    type="checkbox"
                    id={`riskyActivities-${option}`}
                    name="riskyActivities"
                    value={option.toLowerCase()}
                    checked={formData.riskyActivities === option.toLowerCase()}
                    onChange={() =>
                      handleMutuallyExclusiveChange(
                        "riskyActivities",
                        option.toLowerCase()
                      )
                    }
                    className="peer hidden"
                  />
                  <label
                    htmlFor={`riskyActivities-${option}`}
                    className="select-none cursor-pointer rounded-lg border-2 border-gray-200 py-3 px-6 font-bold text-gray-800 transition-colors duration-200 ease-in-out peer-checked:bg-gray-200 peer-checked:text-gray-900 peer-checked:border-gray-200"
                    style={{ height: "52px" }}
                  >
                    {option}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-6">
            <label
              htmlFor="travelAvailability"
              className="block text-sm font-bold mb-2"
            >
              Disponibilidad de viaje:
            </label>
            <p className="text-gray-600 text-xs mb-4">
              Añadir las fechas entre las que se podría realizar el viaje.
            </p>
            <div className="flex space-x-4">
              <div className="w-1/2">
                <label
                  htmlFor="travelAvailabilityStart"
                  className="block text-xs mb-2"
                >
                  Fecha de inicio:
                </label>
                <input
                  type="date"
                  id="travelAvailabilityStart"
                  name="travelAvailabilityStart"
                  value={formData.travelAvailabilityStart}
                  onChange={handleChange}
                  className="border rounded w-full py-2 px-3 focus:outline-none focus:ring-2 focus:ring-gray-600"
                  required
                />
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="travelAvailabilityEnd"
                  className="block text-xs mb-2"
                >
                  Fecha de fin:
                </label>
                <input
                  type="date"
                  id="travelAvailabilityEnd"
                  name="travelAvailabilityEnd"
                  value={formData.travelAvailabilityEnd}
                  onChange={handleChange}
                  className="border rounded w-full py-2 px-3 focus:outline-none focus:ring-2 focus:ring-gray-600"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 px-4">
          <div className="mb-6">
            <label
              htmlFor="activitiesPerDay"
              className="block text-sm font-bold mb-2"
            >
              Número de actividades por día:
            </label>
            <input
              type="number"
              id="activitiesPerDay"
              name="activitiesPerDay"
              value={formData.activitiesPerDay}
              onChange={handleChange}
              className="border rounded w-full py-2 px-3 focus:outline-none focus:ring-2 focus:ring-gray-600"
              required
              min="1"
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="foodPreference"
              className="block text-sm font-bold mb-2"
            >
              Preferencia de Preferencia de comida:
            </label>
            <input
              type="text"
              id="foodPreference"
              name="foodPreference"
              value={formData.foodPreference}
              onChange={handleChange}
              className="border rounded w-full py-2 px-3 focus:outline-none focus:ring-2 focus:ring-gray-600"
              required
            />
          </div>

          <div className="mb-6">
            <label
              htmlFor="destinationPreferences"
              className="block text-sm font-bold mb-2"
            >
              Preferencias de destino:
            </label>
            <div className="flex space-x-4">
              {["Playa", "Montaña", "Ciudad"].map((dest) => (
                <div className="flex" key={dest}>
                  <input
                    type="checkbox"
                    id={`destinationPreferences-${dest}`}
                    name="destinationPreferences"
                    value={dest.toLowerCase()}
                    checked={
                      formData.destinationPreferences === dest.toLowerCase()
                    }
                    onChange={() =>
                      handleMutuallyExclusiveChange(
                        "destinationPreferences",
                        dest.toLowerCase()
                      )
                    }
                    className="peer hidden"
                  />
                  <label
                    htmlFor={`destinationPreferences-${dest}`}
                    className="select-none cursor-pointer rounded-lg border-2 border-gray-200 py-3 px-6 font-bold text-gray-800 transition-colors duration-200 ease-in-out peer-checked:bg-gray-200 peer-checked:text-gray-900 peer-checked:border-gray-200"
                    style={{ height: "52px" }}
                  >
                    {dest}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-6">
            <label htmlFor="parties" className="block text-sm font-bold mb-2">
              Fiestas:
            </label>
            <div className="flex space-x-4">
              {["Sí", "No"].map((option) => (
                <div className="flex" key={option}>
                  <input
                    type="checkbox"
                    id={`parties-${option}`}
                    name="parties"
                    value={option.toLowerCase()}
                    checked={formData.parties === option.toLowerCase()}
                    onChange={() =>
                      handleMutuallyExclusiveChange(
                        "parties",
                        option.toLowerCase()
                      )
                    }
                    className="peer hidden"
                  />
                  <label
                    htmlFor={`parties-${option}`}
                    className="select-none cursor-pointer rounded-lg border-2 border-gray-200 py-3 px-6 font-bold text-gray-800 transition-colors duration-200 ease-in-out peer-checked:bg-gray-200 peer-checked:text-gray-900 peer-checked:border-gray-200"
                    style={{ height: "52px" }}
                  >
                    {option}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-6">
            <label
              htmlFor="accommodationPreference"
              className="block text-sm font-bold mb-2"
            >
              Preferencia de alojamiento:
            </label>
            <input
              type="text"
              id="accommodationPreference"
              name="accommodationPreference"
              value={formData.accommodationPreference}
              onChange={handleChange}
              className="border rounded w-full py-2 px-3 focus:outline-none focus:ring-2 focus:ring-gray-600"
              required
            />
          </div>
          <div>
            <label
              htmlFor="additionalComments"
              className="block text-sm font-bold mb-2"
            >
              Comentarios adicionales:
            </label>
            <textarea
              id="additionalComments"
              name="additionalComments"
              value={formData.additionalComments}
              onChange={handleChange}
              className="border rounded w-full py-2 px-3 focus:outline-none focus:ring-2 focus:ring-gray-600"
              rows="4"
            />
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="bg-gray-900 text-white font-bold py-2 px-20 rounded hover:bg-gray-700"
        disabled={isLoading}
      >
        {isLoading ? "Enviando..." : "Enviar"}
      </button>

      {error && <p className="text-red-500 mt-4">{error}</p>}
    </form>
  );
}
