import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HomeImg from "../images/homeimage.jpeg";
import Contratar from "../components/Contratar";
import About from "../components/about/About";
import Contacto from "../components/Contacto";
import LoginForm from "../components/forms/LoginForm";
import RegisterForm from "../components/forms/RegisterForm";
import { useNavigate } from "react-router";
import { useAuthContext } from "../hooks/useAuthContext";

export default function LandingPage() {
  const { user, dispatch } = useAuthContext();
  const [showPopup, setShowPopup] = useState(false);
  const [formType, setFormType] = useState("login");
  const navigate = useNavigate();

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      dispatch({ type: "LOGIN", payload: JSON.parse(loggedInUser) });
    }
  }, [dispatch]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleLoginSuccess = () => {
    setShowPopup(false);
    navigate("/perfil");
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 1.5, ease: "easeInOut" },
    },
  };

  const handleSwitchForm = () => {
    setFormType(formType === "login" ? "register" : "login");
  };

  return (
    <div>
      <Navbar togglePopup={togglePopup} />
      {showPopup && (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-8 shadow-lg relative">
            <span
              className="absolute top-2 right-4 cursor-pointer text-gray-500 hover:text-gray-800"
              onClick={togglePopup}
            >
              &times;
            </span>
            <div>
              {formType === "login" ? (
                <LoginForm
                  user={user}
                  switchForm={handleSwitchForm}
                  onLoginSuccess={handleLoginSuccess}
                />
              ) : (
                <RegisterForm
                  switchForm={handleSwitchForm}
                  onLoginSuccess={handleLoginSuccess}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <main>
        <motion.section
          className="section"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <section
            id="home"
            className="flex items-center justify-center h-screen relative"
          >
            <div className="absolute inset-0 w-full h-full bg-black opacity-15 z-0"></div>
            <img
              className="absolute inset-0 w-full h-full object-cover z-0"
              src={HomeImg}
              alt="homeImg"
              style={{ mixBlendMode: "multiply" }}
            />
            <div className="relative z-10 text-center text-white">
              <div className="relative mb-10">
                <h1 className="h1 font-bold text-4xl md:text-6xl lg:text-7xl max-w-[767px] mb-4 md:mb-4 leading-tight relative z-10">
                  Despedidas
                </h1>
                <h1 className="h1 font-bold text-4xl md:text-6xl lg:text-7xl max-w-[767px] mb-6 md:mb-8 leading-tight relative z-10">
                  personalizadas
                </h1>
              </div>
              <p className="text-lg md:text-xl lg:text-2xl font-light leading-relaxed mb-10 relative z-10">
                Con Inteligencia Artificial, a tu estilo
              </p>
            </div>
          </section>
        </motion.section>
        <About />
        <Contratar togglePopup={togglePopup} />
        <Contacto />
      </main>
      <Footer />
    </div>
  );
}
