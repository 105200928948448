import React from "react";

export default function AboutInformationSection() {
  return (
    <section id="about" className="relative py-16 bg-gray-100">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "80px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-gray-100 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div className="mx-auto px-20 pb-20">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Quienes Somos?
        </h2>
        <div className="flex flex-col md:flex-row items-center justify-center space-y-12 md:space-y-0 md:space-x-16">
          <div className="max-w-md text-center">
            <h3 className="text-xl font-semibold text-gray-900 mb-2">
              Planifica con Precisión
            </h3>
            <p className="text-gray-700">
              Organiza tu despedida con precisión y facilidad. Personaliza cada
              aspecto, desde actividades hasta presupuesto, con la ayuda de
              nuestra IA integrada.
            </p>
          </div>
          <div className="max-w-md text-center">
            <h3 className="text-xl font-semibold text-gray-900 mb-2">
              Descubre Experiencias Únicas
            </h3>
            <p className="text-gray-700">
              Explora una amplia gama de emocionantes actividades para
              disfrutar, aprovechando las sugerencias que se ajustan
              perfectamente a tus preferencias individuales.
            </p>
          </div>
          <div className="max-w-md text-center">
            <h3 className="text-xl font-semibold text-gray-900 mb-2">
              Simplifica tu Búsqueda
            </h3>
            <p className="text-gray-700">
              Facilitamos tu proceso de planificación y te aseguramos momentos
              inolvidables al dejar atrás la búsqueda interminable de
              actividades.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
