import { useState, useCallback } from "react";

export const useGetPreferences = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getPreferences = useCallback(async (email) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        "https://stag-planner.vercel.app/preferences/getPreferences",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        }
      );

      const json = await response.json();

      if (!response.ok) {
        setIsLoading(false);
        setError(json.error);
        return { error: json.error };
      }

      localStorage.setItem("preferences", JSON.stringify(json));

      setIsLoading(false);
      return { success: true };
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
      return { error: error.message };
    }
  }, []);

  return { getPreferences, isLoading, error };
};
