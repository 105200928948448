import React, { useState } from "react";

import { IoMdClose } from "react-icons/io";
import { CgMenuRight } from "react-icons/cg";
import { motion } from "framer-motion";
import { FaUser } from "react-icons/fa";
import { useAuthContext } from "../hooks/useAuthContext";

export default function MobileNav({ togglePopup }) {
  const { user } = useAuthContext();

  const menuVariants = {
    hidden: {
      x: "100%",
    },
    show: {
      x: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.9],
      },
    },
  };
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <nav className="text-black xl:hidden">
      <div className=" flex gap-6">
        {user && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 1.7 }}
            className="mt-1"
          >
            <a
              href="/perfil"
              className="hover:text-black text-gray-700 transition"
            >
              Perfil
            </a>
          </motion.div>
        )}
        {!user && (
          <button onClick={togglePopup}>
            <FaUser className="size-5" />
          </button>
        )}
        <div
          onClick={() => setOpenMenu(true)}
          className="text-3xl cursor-pointer"
        >
          <CgMenuRight />
        </div>
      </div>

      <motion.div
        variants={menuVariants}
        initial="hidden"
        animate={openMenu ? "show" : ""}
        className="bg-white shadow-2xl w-full absolute top-0 right-0 max-w-sm h-screen z-20"
      >
        <div
          onClick={() => setOpenMenu(false)}
          className="text-4xl absolute z-30 left-4 top-14 text-black cursor-pointer"
        >
          <IoMdClose />
        </div>
        <ul className=" h-full flex flex-col justify-center items-center gap-y-10 text-black font-bold text-3xl">
          <li>
            <a href="/inicio#home" onClick={() => setOpenMenu(false)}>
              Home
            </a>
          </li>
          <li>
            <a href="/inicio#about" onClick={() => setOpenMenu(false)}>
              About
            </a>
          </li>
          <li>
            <a href="/inicio#contratar" onClick={() => setOpenMenu(false)}>
              Contratar
            </a>
          </li>
          <li>
            <a href="/inicio#contacto" onClick={() => setOpenMenu(false)}>
              Contacto
            </a>
          </li>
        </ul>
      </motion.div>
    </nav>
  );
}
