import React from "react";
import Party from "../images/party.jpeg";
import { useAuthContext } from "../hooks/useAuthContext";

export default function Contratar({ togglePopup }) {
  const { user } = useAuthContext();

  return (
    <section id="contratar" className="pt-20 pb-48 mx-auto max-w-5xl px-10">
      <div className="container mx-auto px-4 py-12 border border-gray-200 rounded-lg shadow-md">
        <div className="items-center flex flex-wrap">
          <div className="w-full md:w-6/12 px-4 max-sm:mb-5 max-md:mb-5">
            <img
              alt="..."
              className="w-auto h-auto rounded-lg shadow-lg"
              src={Party}
            />
          </div>
          <div className=" text-right w-full md:w-6/12 pl-10 pr-6">
            <h3 className="text-3xl font-semibold">
              ¡Consigue tu despedida personalizada!
            </h3>
            <p className="mt-4 mb-10 text-lg leading-relaxed text-gray-600">
              Descubre cómo nuestra aplicación puede ayudarte a planificar cada
              detalle de tu evento.
            </p>
            {user && (
              <a
                href="/perfil"
                className="inline-block bg-[#87C5DE] text-white py-3 px-6 rounded-lg text-lg font-semibold transition duration-300 hover:bg-gray-900"
              >
                Contratar Ahora
              </a>
            )}
            {!user && (
              <button
                onClick={togglePopup}
                type="submit"
                className="inline-block bg-[#87C5DE] text-white py-3 px-6 rounded-lg text-lg font-semibold transition duration-300 hover:bg-gray-900"
              >
                Contratar Ahora
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
