import React from "react";
import { TbRulerMeasure } from "react-icons/tb";
import { IoTimeOutline } from "react-icons/io5";
import { CiTrophy, CiDollar } from "react-icons/ci";
import { LuBrain } from "react-icons/lu";
import { GoRocket } from "react-icons/go";
import { TfiExchangeVertical } from "react-icons/tfi";
import { MdSupportAgent } from "react-icons/md";

export default function AboutAdvantajesSection() {
  return (
    <section className="relative ">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "80px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-white fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div className="px-8 mx-auto md:px-12 lg:px-32 max-w-7xl py-20 ">
        <div className="grid grid-cols-2 text-sm font-medium text-center text-gray-500 gap-x-6 gap-y-12 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16 text-balance">
          <div>
            <div>
              <span className="flex items-center justify-center mx-auto bg-gray-100 rounded-full size-12">
                <TbRulerMeasure className="text-gray-600 size-6" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-base text-gray-900">Experiencias a Medida</h3>
              <p className="mt-2">
                Personalización total según gustos y presupuesto.
              </p>
            </div>
          </div>
          <div>
            <div>
              <span className="flex items-center justify-center mx-auto bg-gray-100 rounded-full size-12">
                <GoRocket className="text-gray-600 size-6" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-base text-gray-900">
                Planificación Instantánea
              </h3>
              <p className="mt-2">
                Organización rápida y fácil en pocos clics.
              </p>
            </div>
          </div>
          <div>
            <div>
              <span className="flex items-center justify-center mx-auto bg-gray-100 rounded-full size-12">
                <CiTrophy className="text-gray-600 size-6" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-base text-gray-900">Lo Mejor de lo Mejor</h3>
              <p className="mt-2">
                Selección cuidadosa de actividades y destinos.
              </p>
            </div>
          </div>
          <div>
            <div>
              <span className="flex items-center justify-center mx-auto bg-gray-100 rounded-full size-12">
                <CiDollar className="text-gray-600 size-6" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-base text-gray-900">Gastos Bajo Control</h3>
              <p className="mt-2">
                Sugerencias dentro del presupuesto establecido.
              </p>
            </div>
          </div>
          <div>
            <div>
              <span className="flex items-center justify-center mx-auto bg-gray-100 rounded-full size-12">
                <LuBrain className="text-gray-600 size-6" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-base text-gray-900">Momentos Inolvidables</h3>
              <p className="mt-2">
                Experiencias únicas adaptadas a preferencias.
              </p>
            </div>
          </div>
          <div>
            <div>
              <span className="flex items-center justify-center mx-auto bg-gray-100 rounded-full size-12">
                <IoTimeOutline className="text-gray-600 size-6" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-base text-gray-900">
                Opiniones en Tiempo Real
              </h3>
              <p className="mt-2">
                Retroalimentación instantánea para mejorar.
              </p>
            </div>
          </div>
          <div>
            <div>
              <span className="flex items-center justify-center mx-auto bg-gray-100 rounded-full size-12">
                <TfiExchangeVertical className="text-gray-600 size-6" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-base text-gray-900">
                Adaptabilidad sin Límites
              </h3>
              <p className="mt-2">
                Planes flexibles ante cambios de último minuto.
              </p>
            </div>
          </div>
          <div>
            <div>
              <span className="flex items-center justify-center mx-auto bg-gray-100 rounded-full size-12">
                <MdSupportAgent className="text-gray-600 size-6" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-base text-gray-900">
                Soporte Ininterrumpido
              </h3>
              <p className="mt-2">
                Asistencia disponible las 24/7 durante la planificación.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
