import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { useLogout } from "../hooks/useLogout";
import { useNavigate } from "react-router";
import { useAuthContext } from "../hooks/useAuthContext";
import UserLogo from "../images/UserLogo.jpeg";
import SunsetImage from "../images/sunset.jpeg";
import StagPartyForm from "../components/forms/StagPartyForm";
import PlanRecommendations from "../components/PlanRecommendations";

export default function Profile() {
  const { logout } = useLogout();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [showRecommendations, setShowRecommendations] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user, navigate]);

  if (!user) {
    return null;
  }

  const logoutClick = () => {
    logout();
    navigate("/");
  };

  const toggleView = () => {
    setShowRecommendations((prevState) => !prevState);
  };

  return (
    <div className="bg-gray-100 text-gray-900">
      <Navbar />
      <div className="main-content">
        <div
          className="relative h-[250px] flex items-center justify-center bg-cover bg-center"
          style={{
            backgroundImage: `url(${SunsetImage})`,
          }}
        ></div>
        <div className="container mx-auto mt-8">
          <div className="flex flex-wrap">
            <div className="w-full xl:w-1/3 mb-8 xl:mb-0">
              <div className="bg-white shadow rounded-lg">
                <div className="flex justify-center">
                  <div className="relative -mt-16">
                    <img
                      src={UserLogo}
                      className="rounded-full w-32 h-32 object-cover border-4 border-white bg-gray-300"
                      alt="User"
                    />
                  </div>
                </div>
                <div className="p-4 text-center">
                  <div className="text-center">
                    <h3 className="text-xl font-semibold">{user.username}</h3>
                    <div className="text-gray-600">
                      <i className="fas fa-map-marker-alt mr-2"></i>
                      {user.email}
                    </div>
                    <div className="flex justify-center space-x-4 mt-4 mb-10">
                      <button
                        type="submit"
                        onClick={logoutClick}
                        className="bg-gray-900 text-white font-semibold py-1 px-4 rounded"
                      >
                        Cerrar Sesion
                      </button>
                    </div>
                    <hr className="my-4" />
                    <p className="my-8">
                      Tras rellenar el formulario personalizado, podrás acceder
                      a las diferentes opciones que habrá creado nuestra
                      Inteligencia Artificial en el apartado de{" "}
                      <span className="text-[#2e78bd]"> PLAN</span>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-2/3 pl-4">
              <div className="bg-white shadow rounded-lg">
                <div className="border-b p-4">
                  <div className="flex justify-between items-center">
                    <h3 className="text-lg font-semibold">
                      {showRecommendations
                        ? "Recomendaciones Personalizadas"
                        : "Formulario de Personalización"}
                    </h3>
                    <button
                      onClick={toggleView}
                      className="bg-[#2e78bd] text-white font-semibold py-1 px-4 rounded"
                    >
                      {showRecommendations ? "FORMULARIO" : "PLAN"}
                    </button>
                  </div>
                </div>
                <div className="p-4">
                  {showRecommendations ? (
                    <PlanRecommendations email={user.email} />
                  ) : (
                    <StagPartyForm />
                  )}
                </div>
              </div>
            </div>
          </div>
          <footer className="py-10 relative">
            <div className="mt-4 container mx-auto text-sm text-center relative z-20 inline-block">
              <div className="text-black bg-gray-100 px-10 inline-block">
                StagPlanner by{" "}
                <a
                  href="https://www.linkedin.com/in/jaime-garcia-caamano/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#2e78bd]"
                >
                  Jaime Caamaño
                </a>
              </div>
            </div>
            <hr className=" mt-2 absolute left-0 right-0 border-t border-gray-300 top-[calc(50%)] z-10" />
          </footer>
        </div>
      </div>
    </div>
  );
}
