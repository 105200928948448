import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Profile from "./pages/Profile";
import axios from "axios";

axios.defaults.baseURL = "stag-planner.vercel.app";
axios.defaults.withCredentials = true;

export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/inicio" />} />
        <Route path="/inicio" element={<LandingPage />} />
        <Route path="/perfil" element={<Profile />} />
        <Route path="*" element={<Navigate to="/inicio" />} />
      </Routes>
    </div>
  );
}
