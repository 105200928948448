import { useState } from "react";

export const usePreferences = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const sendPreferences = async (formData) => {
    setIsLoading(true);
    setError(null);

    const convertedData = {
      ...formData,
      budget: Number(formData.budget),
      activitiesPerDay: Number(formData.activitiesPerDay),
    };

    const response = await fetch(
      "https://stag-planner.vercel.app/preferences/upsert",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(convertedData),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      setIsLoading(false);
      setError(data.error);
      return { error: data.error };
    }

    setIsLoading(false);
    return { success: true };
  };

  return { sendPreferences, isLoading, error };
};
