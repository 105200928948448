import React from "react";
import AboutAdvantajesSection from "./AboutAdvantajesSection";
import AboutInformationSection from "./AboutInformationSection";
import AboutSliderSection from "./AboutSliderSection";

export default function About() {
  return (
    <div>
      <AboutInformationSection />
      <AboutAdvantajesSection />
      <AboutSliderSection />
    </div>
  );
}
