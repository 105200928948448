import React from "react";
import { motion } from "framer-motion";
import Logo from "../images/StagPlannerLogo.png";
import { FaUser } from "react-icons/fa";
import MobileNav from "./MobileNav";
import { useAuthContext } from "../hooks/useAuthContext";

export default function Navbar({ togglePopup }) {
  const { user } = useAuthContext();

  const headerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 1, delay: 0.5 },
    },
  };

  return (
    <motion.div initial="hidden" animate="visible" variants={headerVariants}>
      <header className="fixed bg-white w-full px-[20px] lg:px-[100px] z-30 h-[56px] flex items-center">
        <div className="flex flex-col lg:flex-row lg:items-center w-full justify-between font-semibold ">
          <a href="/inicio#home" className="max-w-[200px]">
            <motion.img
              src={Logo}
              alt="logo"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.7 }}
            />
          </a>

          <nav className="hidden xl:flex gap-x-12">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.9 }}
            >
              <a
                href="/inicio#home"
                className="hover:text-black text-gray-700 transition"
              >
                Home
              </a>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 1.1 }}
            >
              <a
                href="/inicio#about"
                className="hover:text-black text-gray-700 transition"
              >
                About
              </a>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 1.3 }}
            >
              <a
                href="/inicio#contratar"
                className="hover:text-black text-gray-700 transition"
              >
                Contratar
              </a>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 1.5 }}
            >
              <a
                href="/inicio#contacto"
                className="hover:text-black text-gray-700 transition"
              >
                Contacto
              </a>
            </motion.div>
            {user && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 1.7 }}
              >
                <a
                  href="/perfil"
                  className="hover:text-black text-gray-700 transition"
                >
                  Perfil
                </a>
              </motion.div>
            )}
          </nav>
        </div>
        {!user && (
          <div className="hidden xl:flex ml-24">
            <ul className="flex gap-x-4 ">
              <li>
                <button onClick={togglePopup}>
                  <FaUser className="size-5" />
                </button>
              </li>
            </ul>
          </div>
        )}
        <MobileNav togglePopup={togglePopup} />
      </header>
    </motion.div>
  );
}
