import React from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useRegister } from "../../hooks/useRegister";
import { useNavigate } from "react-router";

export default function RegisterForm({ switchForm }) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { register, isLoading } = useRegister();
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Las contraseñas no coinciden!");
      return;
    }

    const result = await register(username, email, password);

    if (result?.error) {
      toast.error(result.error);
      console.log(result.error);
    } else {
      navigate("/perfil");
    }
  };

  return (
    <div className="font-[sans-serif] text-[#333]">
      <ToastContainer theme="dark" className="mt-20" />
      <div className="max-h-[80vh] min-h-[30vh] flex flex-col items-center justify-center py-6 px-4">
        <div className="grid md:grid-cols-2 items-center gap-10 max-w-6xl w-full">
          <div className="max-md:text-center">
            <h2 className="lg:text-5xl text-4xl font-extrabold lg:leading-[55px]">
              Obtén tu planificación personalizada!
            </h2>
            <p className="text-sm mt-6">
              Necesitas registrarte para acceder al formulario y obtener una
              planificación personalizada. Nuestro formulario de registro está
              diseñado para hacer el proceso fácil y rápido.
            </p>
            <p className="text-sm mt-10">
              Ya tienes cuenta?{" "}
              <button
                className="text-gray-900 font-semibold hover:underline ml-1"
                onClick={switchForm}
              >
                Inicia Sesión
              </button>
            </p>
          </div>
          <form
            onSubmit={handleRegister}
            className="space-y-6 max-w-md md:ml-auto max-md:mx-auto w-full"
          >
            <h3 className="text-3xl font-extrabold mb-8 max-md:text-center">
              Registro
            </h3>
            <div>
              <input
                name="username"
                type="text"
                autoComplete="username"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="bg-gray-100 w-full text-sm px-4 py-3.5 rounded-md outline-gray-900"
                placeholder="Usuario"
              />
            </div>
            <div>
              <input
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-gray-100 w-full text-sm px-4 py-3.5 rounded-md outline-gray-900"
                placeholder="Email"
              />
            </div>
            <div>
              <input
                name="password"
                type="password"
                autoComplete="new-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="bg-gray-100 w-full text-sm px-4 py-3.5 rounded-md outline-gray-900"
                placeholder="Contraseña"
              />
            </div>
            <div>
              <input
                name="confirm-password"
                type="password"
                autoComplete="new-password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="bg-gray-100 w-full text-sm px-4 py-3.5 rounded-md outline-gray-900"
                placeholder="Confirmar Contraseña"
              />
            </div>
            <div>
              <button
                disabled={isLoading}
                type="submit"
                className="w-full shadow-xl py-2.5 px-4 text-sm font-semibold rounded text-white bg-gray-900 hover:bg-gray-600 focus:outline-none"
              >
                Registrarme
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
