import React from "react";

export default function Footer() {
  return (
    <footer className="relative bg-gray-900 py-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl font-semibold text-white">
              ¡Mantengámonos en contacto!
            </h4>
            <h5 className="text-lg mt-2 mb-2 text-white">
              Responderemos lo más pronto posible.
            </h5>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block uppercase text-white text-sm font-semibold mb-2">
                  Other Resources
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-white hover:text-gray-600 font-semibold block pb-2 text-sm "
                      href="https://www.linkedin.com/in/jaime-garcia-caamano/"
                    >
                      Linked In
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-white hover:text-gray-600 font-semibold block pb-2 text-sm"
                      href="https://github.com/Jaimegcaam"
                    >
                      Github
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-white font-semibold py-1">
              Copyright © {new Date().getFullYear()} StagPlanner by{" "}
              <a
                href="https://www.linkedin.com/in/jaime-garcia-caamano/"
                className="text-white hover:text-gray-600"
              >
                Jaime Caamaño
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
