import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import Madrid from "../../images/Madrid.jpeg";
import Barcelona from "../../images/Barcelona.jpeg";
import Paris from "../../images/Paris.jpeg";
import Roma from "../../images/Roma.jpeg";
import Ibiza from "../../images/Ibiza.jpeg";
import Tokio from "../../images/Tokio.jpeg";
import Vegas from "../../images/Las Vegas.jpeg";
import Amsterdam from "../../images/Amsterdam.jpeg";

export default function CitiesSlider() {
  return (
    <Swiper
      breakpoints={{
        340: {
          slidesPerView: 1.5,
          centeredSlides: true,
          spaceBetween: 15,
        },
        700: {
          slidesPerView: 2.3,
          centeredSlides: true,
          spaceBetween: 15,
        },
        1285: {
          slidesPerView: 3.8,
          centeredSlides: true,
          spaceBetween: 15,
        },
      }}
      loop={true}
      pagination={{ clickable: true }}
      className="max-w-[90%]"
    >
      <SwiperSlide>
        {/* MADRID */}
        <div className="w-full mb-12">
          <img
            alt="Madrid"
            src={Madrid}
            className="shadow-lg rounded-xl max-w-full mx-auto"
            style={{ width: "240px", height: "240px" }}
          />
          <div className="pt-6 text-center">
            <h5 className="text-xl font-bold">Madrid</h5>
            <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
              España
            </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        {/* BARCELONA */}
        <div className="w-full mb-12">
          <img
            alt="Barcelona"
            src={Barcelona}
            className="shadow-lg rounded-xl max-w-full mx-auto"
            style={{ width: "240px", height: "240px" }}
          />
          <div className="pt-6 text-center">
            <h5 className="text-xl font-bold">Barcelona</h5>
            <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
              ESPAÑA
            </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        {/* PARIS */}
        <div className="w-full mb-12">
          <img
            alt="Paris"
            src={Paris}
            className="shadow-lg rounded-xl max-w-full mx-auto"
            style={{ width: "240px", height: "240px" }}
          />
          <div className="pt-6 text-center">
            <h5 className="text-xl font-bold">Paris</h5>
            <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
              FRANCIA
            </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        {/* ROMA */}
        <div className="w-full mb-12">
          <img
            alt="Roma"
            src={Roma}
            className="shadow-lg rounded-xl max-w-full mx-auto"
            style={{ width: "240px", height: "240px" }}
          />
          <div className="pt-6 text-center">
            <h5 className="text-xl font-bold">Roma</h5>
            <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
              ITALIA
            </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        {/* IBIZA */}
        <div className="w-full mb-12">
          <img
            alt="Ibiza"
            src={Ibiza}
            className="shadow-lg rounded-xl max-w-full mx-auto"
            style={{ width: "240px", height: "240px" }}
          />
          <div className="pt-6 text-center">
            <h5 className="text-xl font-bold">Ibiza</h5>
            <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
              ESPAÑA
            </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        {/* TOKIO */}
        <div className="w-full mb-12">
          <img
            alt="Tokio"
            src={Tokio}
            className="shadow-lg rounded-xl max-w-full mx-auto"
            style={{ width: "240px", height: "240px" }}
          />
          <div className="pt-6 text-center">
            <h5 className="text-xl font-bold">Tokio</h5>
            <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
              JAPÓN
            </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        {/* LAS VEGAS */}
        <div className="w-full mb-12">
          <img
            alt="Las Vegas"
            src={Vegas}
            className="shadow-lg rounded-xl max-w-full mx-auto"
            style={{ width: "240px", height: "240px" }}
          />
          <div className="pt-6 text-center">
            <h5 className="text-xl font-bold">Las Vegas</h5>
            <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
              USA
            </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        {/* AMSTERDAM */}
        <div className="w-full mb-12">
          <img
            alt="Amsterdam"
            src={Amsterdam}
            className="shadow-lg rounded-xl max-w-full mx-auto"
            style={{ width: "240px", height: "240px" }}
          />
          <div className="pt-6 text-center">
            <h5 className="text-xl font-bold">Amsterdam</h5>
            <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
              PAISES BAJOS
            </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}
