import React, { useEffect, useState } from "react";
import { useGetPreferences } from "../hooks/useGetPreferences";

const PlanRecommendations = ({ email }) => {
  const {
    getPreferences,
    isLoading: isLoadingPreferences,
    error,
  } = useGetPreferences();
  const [loadedPreferences, setLoadedPreferences] = useState(null);
  const [recommendations, setRecommendations] = useState(null);
  const [isLoadingRecommendations, setIsLoadingRecommendations] =
    useState(false);

  useEffect(() => {
    if (email) {
      getPreferences(email);
    }
  }, [email, getPreferences]);

  useEffect(() => {
    const preferencesFromStorage = localStorage.getItem("preferences");
    if (preferencesFromStorage) {
      setLoadedPreferences(JSON.parse(preferencesFromStorage));
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingRecommendations(true);
      try {
        const response = await fetch(
          "https://api.openai.com/v1/chat/completions",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            },
            body: JSON.stringify({
              model: "gpt-4",
              messages: [
                {
                  role: "user",
                  content: `Dame 3 recomendaciones para despedidas de soltero, teniendo en cuenta los siguientes datos:
                            Nombre del novio/novia: ${loadedPreferences.groomBrideName},
                            Presupuesto: ${loadedPreferences.budget},
                            Disponibilidad para viajar (Inicio): ${loadedPreferences.travelAvailabilityStart},
                            Disponibilidad para viajar (Fin): ${loadedPreferences.travelAvailabilityEnd},
                            Preferencias de viaje: ${loadedPreferences.travelPreferences},
                            Actividades arriesgadas: ${loadedPreferences.riskyActivities},
                            Fiestas: ${loadedPreferences.parties},
                            Actividades por día: ${loadedPreferences.activitiesPerDay},
                            Preferencia de comida: ${loadedPreferences.foodPreference},
                            Preferencia de alojamiento: ${loadedPreferences.accommodationPreference},
                            Preferencias de destino: ${loadedPreferences.destinationPreferences},
                            Comentarios adicionales: ${loadedPreferences.additionalComments}.
                            Las recomendaciones deben ser completas, dando la ciudad, y todas las actividades exactas que hacer, teniendo en cuenta todos los datos.
                            Además, para los hoteles y restaurantes, dáme el nombre del restaurante recomendado.
                            La información debe ser dada dia a dia (teniendo en cuenta el número de dias dados desde el inicio al fin de la disponibilidad del viaje) y cambiando el restaurante para cada comida que se haga.
                            Dame toda información para cada una de las 3 ciudades.
                            Cada Recomendación debe contar con el número de días que hay de disponibilidad desde el inicio al final.`,
                },
              ],
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch recommendations");
        }

        const data = await response.json();
        setRecommendations(data.choices[0].message.content);
      } catch (error) {
        console.error("Error fetching recommendations:", error);
      } finally {
        setIsLoadingRecommendations(false);
      }
    };

    if (loadedPreferences) {
      console.log(loadedPreferences);
      fetchData();
    }
  }, [loadedPreferences]);

  const renderRecommendations = () => {
    if (!recommendations) return null;

    const recArray = recommendations
      .split("Recomendación")
      .filter((line) => line.trim() !== "")
      .map((rec) => "Recomendación " + rec.trim());
    return recArray.map((rec, index) => {
      const [city, ...activities] = rec
        .split("\n")
        .filter((line) => line.trim() !== "");
      return (
        <div
          key={index}
          className="bg-white p-4 m-2 shadow-lg rounded-md border border-gray-200"
        >
          <p className="text-xl font-bold mb-2">{city}</p>
          {activities.map((activity, i) => (
            <p key={i} className="text-lg">
              {activity}
            </p>
          ))}
        </div>
      );
    });
  };

  return (
    <div className="p-6">
      {(isLoadingPreferences || isLoadingRecommendations) && (
        <div className="text-center">
          <div role="status">
            <svg
              aria-hidden="true"
              className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
            <p>
              Obtener las mejores recomendaciones puede tomar unos minutos!!
            </p>
          </div>
        </div>
      )}
      {error && <p>{error}</p>}
      {recommendations && (
        <div>
          <h2 className="text-2xl font-bold mb-4">Recomendaciones de Plan</h2>
          <div className="space-y-4">{renderRecommendations()}</div>
        </div>
      )}
      {!isLoadingPreferences &&
        !isLoadingRecommendations &&
        !error &&
        !loadedPreferences && (
          <div>
            <p>De momento no hay recomendaciones disponibles.</p>
          </div>
        )}
    </div>
  );
};

export default PlanRecommendations;
