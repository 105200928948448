import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../hooks/useLogin";

export default function LoginForm({ switchForm }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, isLoading } = useLogin();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const result = await login(email, password);

    if (result?.error) {
      toast.error(result.error);
      console.log(result.error);
    } else {
      navigate("/perfil");
    }
  };

  const handleForgotPassword = () => {
    console.log("Forgot password clicked");
  };

  return (
    <div className="font-[sans-serif] text-[#333]">
      <ToastContainer theme="dark" className="mt-20" />
      <div className="max-h-[80vh] min-h-[30vh] flex flex-col items-center justify-center py-6 px-4">
        <div className="grid md:grid-cols-2 items-center gap-10 max-w-6xl w-full">
          <div className="max-md:text-center">
            <h2 className="lg:text-5xl text-4xl font-extrabold lg:leading-[55px]">
              ¡Bienvenido de vuelta! Accede a tu cuenta.
            </h2>
            <p className="text-sm mt-6">
              Descubre la planificación meticulosamente diseñada que hemos
              preparado exclusivamente para ti, adaptada a tus necesidades y
              preferencias individuales.
            </p>
            <p className="text-sm mt-10">
              Aún no tienes cuenta?{" "}
              <button
                className="text-gray-900 font-semibold hover:underline ml-1"
                onClick={switchForm}
              >
                Regístrate aquí
              </button>
            </p>
          </div>
          <form
            onSubmit={handleLogin}
            className="space-y-6 max-w-md md:ml-auto max-md:mx-auto w-full"
          >
            <h3 className="text-3xl font-extrabold mb-8 max-md:text-center">
              Inicia Sesión
            </h3>
            <div>
              <input
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-gray-100 w-full text-sm px-4 py-3.5 rounded-md outline-gray-900"
                placeholder="Email"
              />
            </div>
            <div>
              <input
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="bg-gray-100 w-full text-sm px-4 py-3.5 rounded-md outline-gray-900"
                placeholder="Contraseña"
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-gray-900 focus:ring-gray-700 border-gray-300 rounded"
                />
                <label htmlFor="remember-me" className="ml-3 block text-sm">
                  Recuérdame
                </label>
              </div>
              <div className="text-sm">
                <button
                  type="button"
                  className="text-gray-900 hover:text-gray-700"
                  onClick={handleForgotPassword}
                >
                  ¿Has olvidado tu contraseña?
                </button>
              </div>
            </div>
            <div className="!mt-10">
              <button
                disabled={isLoading}
                type="submit"
                className="w-full shadow-xl py-2.5 px-4 text-sm font-semibold rounded text-white bg-gray-900 hover:bg-gray-600 focus:outline-none"
              >
                Iniciar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
